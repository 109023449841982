import React from "react"
import styled from "styled-components"
import mockupFrame from "../../static/phone_mockup.png"
import mockupContent from "../../static/screenshot.png"
import Navbar from "../components/navbar"

const PhoneWrapper = styled.div`
    position: relative;
    margin: 60px;
    width: 250px;
    height: 497px;
`

const PageWrapper = styled.div`
    width: 66.6667%;
    display: flex;
    margin: 0px auto;
    display: flex;
`

const AppIconShadow = styled.div`

`

const AppNameWrapper = styled.h1`
    padding-top: 40px;
`

const AppDescriptionWrapper = styled.div`

`

const ContentWrapper = styled.div`
    float: right;
`

const MockupContentWrap = styled.div`
    width: 240px;
    height: 484px;
    padding 8px 0 0 8px; 
    border-radius: 60px;
    overflow: hidden;
`

const MockupContentImage = styled.img`
    width: 250px;
`

export default function App(props){
    return(
    <>
        <Navbar></Navbar>
        <PageWrapper>
            <PhoneWrapper>
                <MockupContentWrap>
                    <MockupContentImage 
                        src={mockupContent}
                    />
                </MockupContentWrap>
                <div
                    style={{
                    position: "absolute",
                    width: "250px",
                    top: 0,
                    }}
                >
                    <img
                    src={mockupFrame}
                    alt="outlines of shapes and confetti in the background "
                    />
                </div>
            </PhoneWrapper>
            <ContentWrapper>
                <AppNameWrapper>AppName</AppNameWrapper>
                <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Curabitur vitae nunc sed velit dignissim sodales. Odio euismod lacinia at quis risus sed vulputate odio ut. Urna cursus eget nunc scelerisque viverra mauris. Ac odio tempor orci dapibus ultrices in iaculis nunc. Egestas maecenas pharetra convallis posuere morbi leo urna molestie at. Non diam phasellus vestibulum lorem sed risus ultricies tristique. Aenean vel elit scelerisque mauris pellentesque pulvinar pellentesque habitant morbi. Ut porttitor leo a diam sollicitudin tempor. Sit amet tellus cras adipiscing enim eu turpis egestas pretium. Nunc faucibus a pellentesque sit amet. Pellentesque elit eget gravida cum sociis natoque penatibus et. Vel pretium lectus quam id leo in vitae turpis.
                </p>
            </ContentWrapper>
        </PageWrapper>
      </>
    );
}